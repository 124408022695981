<template>
  <section class="about">
    <b-container>
      <b-row class="about-header">
        <b-col
          cols="12"
          class="text-center"
        >
          <h2 class="h2-titles">
            Lalak Doceria
          </h2>

          <h5 class="h5-titles">
            O que era um hobby deu início a um doce negócio...
          </h5>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          sm="12"
          lg="6"
          class="about-img-col"
        >
          <img
            class="img-fluid about-img"
            :src="require('../../assets/img/banner/banner-about.png')"
            alt="Lalak"
          >
        </b-col>

        <b-col
          sm="12"
          lg="6"
          class="about-text-col"
        >
          <div class="about-text">
            <p>{{ getAbout }}</p>

            <img
              class="img-fluid"
              :src="require('../../assets/img/logo/lalak-logo.png')"
              alt=""
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { BContainer, BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'About',

  components: {
    BContainer,
    BRow,
    BCol,
  },

  computed: {
    getAbout() {
      return this.$store.state.system.systemData.about
    },
  },
}
</script>

<style scoped>
@import './style.scss';
</style>
